<template>
  <div class="content">
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="handleSearch">{{
          t("common.search")
        }}</el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          size="small"
          type="primary"
          @click="showDialog(null, 'create')"
          >{{ t("common.create") }}</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column
        type="index"
        width="80"
        :label="t('common.number')"
      ></el-table-column>
      <el-table-column
        prop="name"
        :label="t('authority.name')"
      ></el-table-column>
      <el-table-column
        prop="description"
        :label="t('authority.description')"
      ></el-table-column>
      <el-table-column fixed="right" :label="t('common.action')">
        <template #default="scope">
          <el-button @click="openDrawer(scope.row)" type="text" size="small">{{
            t("common.set_authority")
          }}</el-button>
          <el-button
            @click="showDialog(scope.row, 'detail')"
            type="text"
            size="small"
            >{{ t("common.detail") }}</el-button
          >
          <el-button
            @click="showDialog(scope.row, 'update')"
            type="text"
            size="small"
            >{{ t("common.edit") }}</el-button
          >
          <el-button
            @click="handleDelete(scope.row)"
            type="text"
            class="text"
            size="small"
            >{{ t("common.delete") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>

    <AuthorityDialog
      ref="authorityDialog"
      :type="dialogType"
      :row="currentRow"
      @submit="handleCreate"
      @edit="handleEdit"
    ></AuthorityDialog>

    <el-drawer
      v-model="drawer"
      :with-header="false"
      size="40%"
      :title="t('authority.authority')"
      v-if="drawer"
    >
      <el-tabs :before-leave="autoEnter" class="role-box" type="border-card">
        <el-tab-pane :label="t('authority.menus')" v-if="false">
          <menus :row="activeRow" ref="tabMenus"></menus>
        </el-tab-pane>
        <el-tab-pane :label="t('authority.apis')">
          <apis :row="activeRow" ref="tabApis" @close="closeDrawer"></apis>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref, getCurrentInstance } from "vue";
import { search, get, create, update, remove } from "@/api/authority";
import AuthorityDialog from "./authority_dialog.vue";
import Apis from "./drawer/apis.vue";
import Menus from "./drawer/menus.vue";
import { ElMessage } from "element-plus";
export default {
  name: "authority",
  components: {
    AuthorityDialog,
    Apis,
    Menus,
  },
  setup() {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();

    const drawer = ref(false);

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
    });

    const activeRow = reactive({});

    const dialogType = ref(null);
    const currentRow = ref({});
    const authorityDialog = ref(null);

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const showDialog = (row, type) => {
      dialogType.value = type;
      currentRow.value = row;

      authorityDialog.value.openDialog();
    };

    const autoEnter = (activeTab, oldTab) => {
      console.log("save", activeTab, oldTab);
      const tabPane = ["tabMenus", "tabApis"];
      if (oldTab) {
        if (proxy.$refs[tabPane[oldTab]].needConfirm) {
          proxy.$refs[tabPane[oldTab]].needConfirm.enterAndNext();
          proxy.$refs[tabPane[oldTab]].needConfirm.needConfirm = false;
        }
      }
    };

    const handleCreate = async (row) => {
      const res = await create(row);
      if (res.error_code === 0) {
        authorityDialog.value.closeDialog();
        ElMessage.success(t("common.create_prompt"));
        getAuthorityData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDetail = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
    };

    const handleEdit = async (row) => {
      const res = await update(row);
      if (res.error_code === 0) {
        authorityDialog.value.closeDialog();
        ElMessage.success(t("common.update_prompt"));
        getAuthorityData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDelete = async (row) => {
      const res = await remove({ id: row.id });
      if (res.error_code === 0) {
        ElMessage.success(t("common.delete_prompt"));
        getAuthorityData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSearch = (params) => {
      console.log("params", params);
      getAuthorityData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getAuthorityData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getAuthorityData();
    };

    const getAuthorityData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const openDrawer = (row) => {
      drawer.value = true;
      activeRow.value = row;
    };

    const closeDrawer = () => {
      drawer.value = false
      activeRow.value = {}
    } 

    onMounted(() => {
      // 获取数据
      getAuthorityData();
    });
    return {
      t,
      drawer,
      params,
      tableInfo,
      authorityDialog,
      currentRow,
      showDialog,
      dialogType,
      handleCreate,
      handleDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      openDrawer,
      activeRow,
      autoEnter,
      closeDrawer
    };
  },
};
</script>

<style lang="scss">
.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}

.role-box {
  .el-tabs__content {
    height: calc(100vh);
    overflow: auto;
    padding-bottom: 80px;
  }
}
</style>
