<template>
  <div class="content">
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="handleSearch">{{
          t("common.search")
        }}</el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          size="small"
          type="primary"
          @click="showDialog(null, 'create')"
          >{{ t("common.create") }}</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column
        type="index"
        width="80"
        :label="t('common.number')"
      ></el-table-column>
      <el-table-column prop="name" :label="t('user.name')"></el-table-column>
      <el-table-column prop="email" :label="t('user.email')"></el-table-column>
      <el-table-column
        prop="mobile"
        :label="t('user.mobile')"
      ></el-table-column>
      <el-table-column
        prop="description"
        :label="t('user.description')"
      ></el-table-column>
      <el-table-column :label="t('user.authority')">
        <template #default="scope">
          <span>{{ scope.row.authority.name }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="password" :label="t('user.password')"></el-table-column> -->
      <el-table-column fixed="right" :label="t('common.action')" width="120">
        <template #default="scope">
          <el-button
            @click="showDialog(scope.row, 'detail')"
            type="text"
            size="small"
            >{{ t("common.detail") }}</el-button
          >
          <el-button
            @click="showDialog(scope.row, 'update')"
            type="text"
            size="small"
            >{{ t("common.edit") }}</el-button
          >
          <el-button
            @click="handleDelete(scope.row)"
            type="text"
            class="text"
            size="small"
            >{{ t("common.delete") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>

    <UserDialog
      ref="userDialog"
      :type="dialogType"
      :row="currentRow"
      @submit="handleCreate"
      @edit="handleEdit"
    ></UserDialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref } from "vue";
import { search, get, create, update, remove } from "@/api/user";
import UserDialog from "./user_dialog.vue";
import { ElMessage } from "element-plus";
export default {
  name: "user",
  components: {
    UserDialog,
  },
  setup() {
    const { t } = useI18n();

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
    });

    const dialogType = ref(null);
    const currentRow = ref({});
    const userDialog = ref(null);

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const showDialog = (row, type) => {
      dialogType.value = type;
      currentRow.value = row;

      userDialog.value.openDialog();
    };

    const handleCreate = async (row) => {
      const res = await create(row);
      if (res.error_code === 0) {
        userDialog.value.closeDialog();
        getUserData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDetail = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
    };

    const handleEdit = async (row) => {
      const res = await update(row);
      if (res.error_code === 0) {
        userDialog.value.closeDialog();
        getUserData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDelete = async (row) => {
      const res = await remove({ id: row.id });
      if (res.error_code === 0) {
        getUserData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSearch = (params) => {
      console.log("params", params);
      getUserData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getUserData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getUserData();
    };

    const getUserData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    onMounted(() => {
      // 获取数据
      getUserData();
    });
    return {
      t,
      params,
      tableInfo,
      userDialog,
      currentRow,
      showDialog,
      dialogType,
      handleCreate,
      handleDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}
</style>
