import service from '@/utils/request'

// 获取Api列表
export const search = (params) => {
    return service({
        url: '/apis',
        method: 'get',
        params
    })
}

// 获取指定Api的详情
export const get = (params) => {
    return service({
        url: `/api/${params.id}`,
        method: 'get'
    })
}

// 创建Api
export const create = (data) => {
    return service({
        url: '/api',
        method: 'post',
        data
    })
}

// 更新Api信息
export const update = (data) => {
    return service({
        url: `/api/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Api
export const remove = (data) => {
    return service({
        url: `/api/${data.id}`,
        method: 'delete',
        data
    })
}