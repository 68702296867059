import service from '@/utils/request'

// 获取当前AuthorityID 对应的api权限列表
export const getPoliciesByAuthorityID = (params) => {
    return service({
        url: '/policies',
        method: 'get',
        params
    })
}

// 更新当前Authority ID对应的api权限
export const updatePolicies = (data) => {
    return service({
        url: `/policy/${data.id}`,
        method: 'put',
        data
    })
}