import service from '@/utils/request'

// 获取Authority列表
export const search = (params) => {
    return service({
        url: '/authorities',
        method: 'get',
        params
    })
}

// 获取指定Authority的详情
export const get = (params) => {
    return service({
        url: `/authority/${params.id}`,
        method: 'get'
    })
}

// 创建Authority
export const create = (data) => {
    return service({
        url: '/authority',
        method: 'post',
        data
    })
}

// 更新Authority信息
export const update = (data) => {
    return service({
        url: `/authority/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Authority
export const remove = (data) => {
    return service({
        url: `/authority/${data.id}`,
        method: 'delete',
        data
    })
}