import service from '@/utils/request'

// 获取Menus列表
export const search = (params) => {
    return service({
        url: '/menus',
        method: 'get',
        params
    })
}

// 获取指定Menus的详情
export const get = (params) => {
    return service({
        url: `/menu/${params.id}`,
        method: 'get'
    })
}

// 创建Menus
export const create = (data) => {
    return service({
        url: '/menu',
        method: 'post',
        data
    })
}

// 更新Menus信息
export const update = (data) => {
    return service({
        url: `/menu/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Menus
export const remove = (data) => {
    return service({
        url: `/menu/${data.id}`,
        method: 'delete',
        data
    })
}

// 获取Menus树形列表
export const list = (params) => {
    return service({
        url: '/list',
        method: 'get',
        params
    })
}

// 获取动态菜单树，根据authorityID 菜单树形结构
export const getDynamicMenuTree = (params) => {
    return service({
        url: `/dynamic/${params.authority_id}`,
        method: 'get'
    })
}

// 获取角色对应的菜单
export const getAuthorityMenu = (params) => {
    return service({
        url: '/menus/getAuthorityMenu',
        method: 'get',
        params
    })
}

// 为角色增加menu
export const addAuthorityMenu = (data) => {
    return service({
        url: '/menus/addAuthorityMenu',
        method: 'post',
        data
    })
}
