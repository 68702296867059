<template>
  <div>
    <div class="clearflex">
      <el-button
        @click="relation"
        class="fl-right"
        size="small"
        type="primary"
        >{{ t("common.save") }}</el-button
      >
    </div>
    <el-tree
      :data="menuInfo.menuTreeData"
      :default-checked-keys="menuInfo.menuTreeIds"
      :props="menuDefaultProps"
      @check="nodeChange"
      default-expand-all
      highlight-current
      node-key="id"
      ref="menuTree"
      show-checkbox
    >
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <span>{{ node.label }}</span>
          <span>
            <el-button
              type="text"
              size="mini"
              :style="{
                color: row.defaultRouter == data.name ? '#E6A23C' : '#85ce61',
              }"
              :disabled="!node.checked"
            >
              <!-- {{ row.defaultRouter == data.name ? "首页" : "设为首页" }} -->
            </el-button>
          </span>
        </span>
      </template>
    </el-tree>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import { list, getAuthorityMenu, addAuthorityMenu } from "@/api/menus";
import { ref, reactive, getCurrentInstance, onBeforeMount } from "vue";
import { ElMessage } from "element-plus";
export default {
  name: "drawer-menus",
  props: {
    row: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const menuInfo = reactive({
      menuTreeData: [],
      menuTreeIds: [],
    });
    const needConfirm = ref(false);
    const menuDefaultProps = reactive({
      children: "children",
      label: function (data) {
        return data.meta.title;
      },
    });

    const nodeChange = () => {
      needConfirm.value = true;
    };
    // 暴露给外层使用的切换拦截统一方法
    const enterAndNext = () => {
      relation();
    };
    // 关联树 确认方法
    const relation = async () => {
      const checkArr = proxy.$refs.menuTree.getCheckedNodes(false, true);
      const res = await addAuthorityMenu({
        menus: checkArr,
        authority_id: props.row.value.id,
      });
      if (res.error_code === 0) {
        // 提示菜单设置成功
        ElMessage.success(t("common.update_prompt"));
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const initMenu = async () => {
      // 获取所有菜单树
      const res = await list();
      menuInfo.menuTreeData = res.response;
      const res1 = await getAuthorityMenu({
        authority_id: props.row.value.id,
      });
      const menus = res1.response;
      const arr = [];
      menus.map((item) => {
        // 防止直接选中父级造成全选
        if (!menus.some((same) => same.parent_id === item.menu_id)) {
          arr.push(item.menu_id);
        }
      });
      menuInfo.menuTreeIds = [...arr];
    };
    onBeforeMount(() => {
      initMenu();
    });
    return {
      t,
      menuInfo,
      menuDefaultProps,
      nodeChange,
      relation,
      enterAndNext
    };
  },
};
</script>
<style lang="scss">
.clearflex {
  *zoom: 1;
}

.clearflex:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}
</style>