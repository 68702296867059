<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>{{t('common.reset_password')}}</span>
      </div>
    </template>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="userRuleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item :label="t('common.user')" prop="id">
        <el-select v-model="ruleForm.id" style="width: 68%" clearable>
          <el-option
            v-for="item in profile.users"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="t('common.password')" prop="password">
        <el-input
          v-model="ruleForm.password"
          style="width: 68%"
          :show-password="true"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="verifyPwd"
          >{{t('common.reset_password')}}</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref } from "vue";
import { search, userProfile } from "@/api/user";
import { ElMessage } from "element-plus";
export default {
  name: "password",
  setup() {
    const { t } = useI18n();

    const profile = reactive({
      users: [],
    });

    const ruleForm = reactive({
      id: "",
      password: "",
    });

    const checkPassword = (rule, value, callback) => {
      const pwd = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{15,}$/;
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (!pwd.test(value)) {
        callback(
          new Error(
            "密码长度必须大于15,至少包含数字、大写字母、小写字母、特殊字符"
          )
        );
      } else {
        callback();
      }
    };

    const userRuleForm = ref(null);

    const rules = reactive({
      id: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      password: [
        {
          validator: checkPassword,
          // message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const getUserData = async () => {
      const res = await search({ page: 1, per_page: 1000 });
      if (res.error_code === 0) {
        profile.users = res.response.data;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const verifyPwd = () => {
      userRuleForm.value.validate((valid) => {
        if (valid) {
          resetPassword()
        }
      })
    }

    const resetPassword = async () => {
      const res = await userProfile({
        id: parseInt(ruleForm.id),
        password: ruleForm.password,
      });
      if (res.error_code === 0) {
        ElMessage.success(t("common.reset_prompt"));
      } else {
        ElMessage.error(res.error_message);
      }
    };

    onMounted(() => {
      // 获取数据
      getUserData();
    });
    return {
      t,
      profile,
      ruleForm,
      rules,
      userRuleForm,
      resetPassword,
      checkPassword,
      verifyPwd
    };
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin: 20px 10px 0px 10px;
}
</style>