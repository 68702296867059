<template>
  <div>
    <div class="clearflex">
      <el-button
        @click="saveApi"
        class="fl-right"
        size="small"
        type="primary"
        >{{ t("common.save") }}</el-button
      >
    </div>
    <el-tree
      :data="tree.data"
      :default-checked-keys="tree.keys"
      :props="defaultProps"
      @check="nodeChange"
      default-expand-all
      highlight-current
      node-key="onlyID"
      ref="apiTree"
      show-checkbox
    ></el-tree>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { search } from "@/api/api";
import { getPoliciesByAuthorityID, updatePolicies } from "@/api/policy";
import { reactive, ref, onBeforeMount, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
export default {
  name: "drawer-apis",
  props: {
    row: Object,
  },
  setup(props) {
    console.log('row', props.row)
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();

    const tree = reactive({
      data: [], //tree数据
      keys: [], //tree默认选中的项id
    });
    const defaultProps = reactive({
      children: "children",
      label: "description",
    });
    const needConfirm = ref(false);
    const nodeChange = () => {
      needConfirm.value = true;
    };

    const initApis = async () => {
      const resApis = await search({ page: 1, per_page: 1000 });
      const apis = resApis.response.data;
      tree.data = [...buildApiTree(apis)];
      console.log('data', tree.data)
      const res = await getPoliciesByAuthorityID({
        authority_id: props.row.value.id,
      });
      tree.keys = [];
      if (res.response.policies) {
        res.response.policies.map((item) => {
          tree.keys.push("p:" + item.path + "m:" + item.method);
        });
      } else {
        tree.data.forEach(obj => {
          if (obj.children && obj.children.length > 0) {
            obj.children.forEach(ele => {
              if (ele.required) {
                tree.keys.push(ele.onlyID)
              }
            })
          }
        })
      }
      // res.response.policies &&
      //   res.response.policies.map((item) => {
      //     tree.keys.push("p:" + item.path + "m:" + item.method);
      //   });
      tree.keys = [...tree.keys];
      console.log('keys', tree.keys)
    };

    // 创建api树方法
    const buildApiTree = (apis) => {
      const apiObj = new Object();
      apis &&
        apis.map((item) => {
          item.onlyID = "p:" + item.path + "m:" + item.method;
          if (Object.prototype.hasOwnProperty.call(apiObj, item.api_group)) {
            apiObj[item.api_group].push(item);
          } else {
            Object.assign(apiObj, { [item.api_group]: [item] });
          }
        });
      const apiTree = [];
      for (const key in apiObj) {
        const treeNode = {
          ID: key,
          description: key + "组",
          children: apiObj[key],
        };
        apiTree.push(treeNode);
      }
      return apiTree;
    };

    const saveApi = async () => {
      const checkNode = proxy.$refs.apiTree.getCheckedNodes(true);
      const policies = [];
      checkNode &&
        checkNode.map((item) => {
          var policy = {
            path: item.path,
            method: item.method,
          };
          policies.push(policy);
        });
      const res = await updatePolicies({
        id: props.row.value.id,
        policies: policies,
      });
      if (res.error_code === 0) {
         ElMessage.success(t("common.update_prompt"));
         proxy.$emit('close')
      } else {
        ElMessage.error(res.error_message);
      }
    };

    // 暴露给外层使用的切换拦截统一方法
    const enterAndNext = () => {
      saveApi()
    }

    onBeforeMount(() => {
      // 获取数据
      initApis();
    });
    return {
      t,
      tree,
      defaultProps,
      nodeChange,
      saveApi,
      enterAndNext
    };
  },
};
</script>

<style lang="scss" scoped>
.clearflex {
  *zoom: 1;
}

.clearflex:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}
</style>