<template>
  <div>
    <el-dialog
      :title="getDialogTitle"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="userForm">
        <el-form-item :label="t('user.name')" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="t('user.email')" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="t('user.mobile')" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="t('user.description')" prop="description">
          <el-input v-model="ruleForm.description"></el-input>
        </el-form-item>
        <el-form-item
          :label="t('user.password')"
          prop="password"
          v-if="type === 'create'"
        >
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item :label="t('user.bind_authority')" prop="authority_id">
          <el-select v-model="ruleForm.authority_id" style="width: 100%">
            <el-option
              v-for="item in authorities"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  ref,
  reactive,
  watch,
  getCurrentInstance,
  computed,
  onMounted,
} from "vue";
import { search } from "@/api/authority";
export default {
  name: "user_dialog",
  props: {
    row: Object,
    type: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const dialogVisible = ref(false);
    let authorities = reactive([]);

    const ruleForm = reactive({
      name: "",
      email: "",
      mobile: "",
      description: "",
      password: "",
      authority_id: "",
    });

    const checkPassword = (rule, value, callback) => {
      const pwd = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{15,}$/;
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (!pwd.test(value)) {
        callback(
          new Error(
            "密码长度必须大于15,至少包含数字、大写字母、小写字母、特殊字符"
          )
        );
      } else {
        callback();
      }
    };

    const userForm = ref(null);

    const rules = reactive({
      name: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      mobile: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      password: [
        {
          validator: checkPassword,
          // message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      authority_id: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      userForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      userForm.value.validate((valid) => {
        if (valid) {
          if (props.type === "create") {
            proxy.$emit("submit", ruleForm);
          } else {
            proxy.$emit("edit", ruleForm);
          }
        }
      });
    };

    const handleClose = () => {
      userForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const openDialog = () => {
      userForm.value?.resetFields();
      dialogVisible.value = true;
    };

    const closeDialog = () => {
      dialogVisible.value = false;
    };

    watch(props, () => {
      if (props.type !== "create") {
        ruleForm.id = props.row.id;
        ruleForm.name = props.row.name ? props.row.name : "";
        ruleForm.email = props.row.email ? props.row.email : "";
        ruleForm.mobile = props.row.mobile ? props.row.mobile : "";
        ruleForm.description = props.row.description
          ? props.row.description
          : "";
        ruleForm.password = props.row.password ? props.row.password : "";
        ruleForm.authority_id = props.row.authority.id
          ? props.row.authority.id
          : "";
      } else {
        ruleForm.name = "";
        ruleForm.email = "";
        ruleForm.mobile = "";
        ruleForm.description = "";
        ruleForm.password = "";
        ruleForm.authority_id = "";
      }
    });

    const getDialogTitle = computed(() => {
      switch (props.type) {
        case "create":
          return t("common.create");
        case "detail":
          return t("common.detail");
        default:
          return t("common.edit");
      }
    });

    const getAuthorityData = async () => {
      const res = await search({ page: 1, per_page: 100 });
      if (res.error_code === 0) {
        authorities.push(...res.response.data);
      }
    };

    onMounted(() => {
      // 获取数据
      getAuthorityData();
    });

    return {
      t,
      authorities,
      dialogVisible,
      userForm,
      rules,
      ruleForm,
      openDialog,
      closeDialog,
      handleClose,
      resetForm,
      submitForm,
      getDialogTitle,
      getAuthorityData,
      checkPassword
    };
  },
};
</script>

<style>
</style>